import { setContext } from '@apollo/client/link/context';
import Cookie from '~/shared/utils/Cookie';
import setHeader from '~/shared/utils/setHeader';
import getUniqueDeviceId from '../../getUniqueDeviceId';

const getAuthLink = ({
  requestId,
  forwardedFor,
  context,
  token,
  uniqueDeviceId = getUniqueDeviceId(context),
}: ApolloLinkOptions = {}) =>
  setContext((_, { headers }) => {
    const authToken = Cookie.get('token', context) || token;
    const userAgent = process.env.WHITELIST_USER_AGENT;
    return {
      headers: {
        ...headers,
        ...setHeader('x-forwarded-for', forwardedFor),
        ...setHeader('x-request-id', requestId),
        ...setHeader('x-unique-device-id', uniqueDeviceId),
        ...setHeader('User-Agent', userAgent),
        ...setHeader(`Authorization`, authToken, () => `Bearer ${authToken}`),
      },
    };
  });

export default getAuthLink;
