import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const variables = filterSensitiveInfo(operation?.variables);
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} | [Operation name]: ${operation?.operationName} | [Variables]: ${JSON.stringify(
          variables ?? {},
        )}`,
      );
    });
  }

  if (networkError) {
    console.error(
      `[Network error]: ${networkError} | [Operation name]: ${operation?.operationName} | [Variables]: ${JSON.stringify(
        variables ?? {},
      )}`,
    );
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterSensitiveInfo = (variables: Record<string, any>) => {
  if (variables?.password || variables?.otp) {
    const filteredVariables = { ...variables, password: '*****', otp: '****' };
    return filteredVariables;
  }
  return variables;
};

export default errorLink;
