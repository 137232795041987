import { ApolloLink, Operation, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import CustomSubscriptionClient from './CustomSubscriptionClient';

const getSubscriptionLink = (uri: string, link: ApolloLink) => {
  const parsedUri = parseUriAsWebsocket(uri);
  const wsLink = getWebSocketLink(parsedUri);
  return split(isSubscription, wsLink, link);
};

const parseUriAsWebsocket = (uri: string) => {
  const absoluteUri = getAbsoluteUri(uri);
  return absoluteUri.replace(/(http)(s)?\:\/\//, 'wss://');
};

const getAbsoluteUri = (uri: string) => {
  if (!uri || !uri.startsWith('http')) {
    return `${location.origin}/graphql`;
  }
  return uri;
};

const getWebSocketLink = (uri: string) => {
  const client = new CustomSubscriptionClient(uri, { reconnect: true });
  return new WebSocketLink(client);
};

const isSubscription = ({ query }: Operation) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};

export default getSubscriptionLink;
