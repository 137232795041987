/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable immutable/no-mutation */
import { print } from 'graphql/language/printer';
import { ClientOptions, SubscriptionClient } from 'subscriptions-transport-ws';
import getToken from '~/shared/token/getToken';
import CustomMessageTypes from './helpers/CustomMessageTypes';
import mapMessageType from './helpers/mapMessageType';
import MessageTypes from './helpers/MessageTypes';
import parseMessage from './helpers/parseMessage';

class CustomSubscriptionClient extends SubscriptionClient {
  constructor(url: string, options?: ClientOptions) {
    super(url, options);
    (this as any).buildMessage = this._buildMessage;
    (this as any).processReceivedData = this._processReceivedData;
    (this as any).tryReconnect = this._tryReconnect;
    this.restartTimeout();
  }

  public restartTimeout() {
    const self = this as any;
    if (self.timeout) {
      clearTimeout(self.timeout);
    }
    self.timeout = setTimeout(() => {
      self.close(false, true);
    }, 30 * 1000);
  }

  private _buildMessage = (id: string, eventName: string, eventData: any) => {
    if (eventName === CustomMessageTypes.GQL_CONNECTION_INIT) {
      this.requeueSubscriptions();
    }
    if (eventName === 'stop') {
      return {
        eventName: 'unsubscribe',
        eventData: { id },
        token: getToken(),
      };
    }
    const eventDataToReturn = eventData?.query
      ? {
          id,
          ...eventData,
          query:
            typeof eventData.query === 'string'
              ? eventData.query
              : print(eventData.query),
        }
      : eventData;

    if (eventDataToReturn?.variables?.input && id) {
      eventDataToReturn.variables.input.clientSubscriptionId = id;
    }
    return {
      id,
      eventName: mapMessageType(eventName) || eventName,
      eventData: eventDataToReturn,
      token: getToken(),
    };
  };

  public requeueSubscriptions() {
    const self = this as any;
    Object.keys(this.operations).forEach((key) => {
      if (
        self.unsentMessagesQueue.some((el: { id: string }) => el.id === key)
      ) {
        return;
      }
      self.unsentMessagesQueue.push(
        self.buildMessage(
          key,
          MessageTypes.GQL_START,
          self.operations[key].options,
        ),
      );
    });
  }

  private _tryReconnect() {
    const self = this as any;
    if (!self.reconnect || self.backoff.attempts >= self.reconnectionAttempts) {
      return;
    }

    if (!self.reconnecting) {
      self.reconnecting = true;
    }

    self.clearTryReconnectTimeout();

    const delay = self.backoff.duration();
    self.tryReconnectTimeoutId = setTimeout(() => {
      self.connect();
    }, delay);
  }

  private _processReceivedData = (receivedData: string) => {
    const self = this as any;
    const firstKA = typeof self.wasKeepAliveReceived === 'undefined';
    const { parsedMessage, opId } = parseMessage(receivedData);

    if (
      [
        CustomMessageTypes.GQL_DATA,
        CustomMessageTypes.GQL_COMPLETE,
        CustomMessageTypes.GQL_ERROR,
      ].includes(parsedMessage.eventName) &&
      !self.operations[opId]
    ) {
      self.unsubscribe(opId);
      return;
    }
    switch (parsedMessage.eventName) {
      case CustomMessageTypes.GQL_CONNECTION_ERROR:
        if (self.connectionCallback) {
          self.connectionCallback(parsedMessage.eventData);
        }
        break;
      case CustomMessageTypes.GQL_CONNECTION_ACK:
        self.eventEmitter.emit(self.reconnecting ? 'reconnected' : 'connected');
        self.reconnecting = false;
        self.backoff.reset();
        self.maxConnectTimeGenerator.reset();
        if (self.connectionCallback) {
          self.connectionCallback();
        }
        break;
      case CustomMessageTypes.GQL_COMPLETE:
        self.operations[opId].handler(null, null);
        delete self.operations[opId];
        break;
      case CustomMessageTypes.GQL_ERROR:
        self.operations[opId].handler(
          self.formatErrors(parsedMessage.eventData),
          null,
        );
        delete self.operations[opId];
        break;
      case CustomMessageTypes.GQL_DATA:
        const parsedEventData = !parsedMessage.eventData.errors
          ? parsedMessage.eventData
          : {
              ...parsedMessage.eventData,
              errors: self.formatErrors(parsedMessage.eventData.errors),
            };
        const { handler } = self.operations[opId];
        handler(null, parsedEventData);
        break;
      case CustomMessageTypes.GQL_CONNECTION_KEEP_ALIVE:
        self.restartTimeout();
        self.sendMessage(undefined, 'PONG', { time: new Date().getTime() });
        self.wasKeepAliveReceived = true;
        self.flushUnsentMessagesQueue();
        if (firstKA) {
          self.checkConnection();
        }
        if (self.checkConnectionIntervalId) {
          clearInterval(self.checkConnectionIntervalId);
          self.checkConnection();
        }
        self.checkConnectionIntervalId = setInterval(
          self.checkConnection.bind(this),
          self.wsTimeout,
        );
        break;
      default:
        throw new Error(`Invalid message type! ${parsedMessage}`);
    }
  };
}

export default CustomSubscriptionClient;
