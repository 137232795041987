import Cookie from '~/shared/utils/Cookie';

const KEY = 'uniqueDeviceId';

const getUniqueDeviceId = (args?: Pick<CookieOptions, 'req' | 'res'>) => {
  const { res, req } = args ?? {};
  const clientUniqueIdInCookie = Cookie.get(KEY, { res, req });
  return clientUniqueIdInCookie as string;
};

export default getUniqueDeviceId;
