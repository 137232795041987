import type { NormalizedCacheObject } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';
import isSsr from '~/shared/utils/isSsr';
import createApolloClient from './createApolloClient';

type Props = ApolloLinkOptions & { initialState?: NormalizedCacheObject };

const initApollo = ({
  initialState,
  forwardedFor,
  isPrivate,
  requestId,
  token,
  uri,
}: Props) => {
  const _apolloClient =
    global['apolloClient'] ??
    createApolloClient({
      forwardedFor,
      isPrivate,
      requestId,
      token,
      uri,
    });
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state get hydrated here
  if (initialState) {
    const existingData = _apolloClient.cache.extract();
    _apolloClient.cache.restore(mergeDeep(existingData, initialState));
  }
  // For SSG and SSR always create a new Apollo Client
  if (isSsr()) {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!global['apolloClient']) {
    // eslint-disable-next-line immutable/no-mutation
    global['apolloClient'] = _apolloClient;
  }
  return _apolloClient;
};

export default initApollo;
