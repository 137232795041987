import { ApolloClient } from '@apollo/client';
import getCache from './getCache';
import getLink from './getLink';

type Props = ApolloLinkOptions;

const createApolloClient = (options: Props = {}) =>
  new ApolloClient({
    ssrMode: true,
    ssrForceFetchDelay: 600,
    link: getLink(options),
    cache: getCache(),
  });
export default createApolloClient;
