import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

const getCache = () =>
  new InMemoryCache({
    typePolicies: {
      CasinoNotification: { fields: { notifications: relayStylePagination() } },
      CasinoTournamentsPublic: { merge: true },
      CasinoBoostsPublic: { merge: true },
      UserManagementSettings: { merge: true },
      Features: { merge: false },
      BadgesPublic: { merge: true },
    },
  });

export default getCache;
