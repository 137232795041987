import { useMemo } from 'react';
import type { NormalizedCacheObject } from '@apollo/client';
import initApollo from '~/shared/apollo/initApollo';

type Props = ApolloLinkOptions & { initialState?: NormalizedCacheObject };
const useApollo = ({ initialState, uri }: Props) => {
  const store = useMemo(
    () => initApollo({ initialState, uri }),
    [initialState, uri],
  );
  return store;
};

export default useApollo;
