enum CustomMessageTypes {
  GQL_CONNECTION_INIT = 'connection_init', // Client -> Server
  GQL_CONNECTION_ACK = 'connection_ack', // Server -> Client
  GQL_CONNECTION_ERROR = 'connection_error', // Server -> Client
  GQL_CONNECTION_KEEP_ALIVE = 'PING', // Server -> Client
  GQL_CONNECTION_TERMINATE = 'connection_terminate', // Client -> Server
  GQL_START = 'subscribe', // Client -> Server
  GQL_DATA = 'subscription update', // Server -> Client
  GQL_ERROR = 'error', // Server -> Client
  GQL_COMPLETE = 'complete', // Server -> Client
  GQL_STOP = 'unsubscribe', // Client -> Server
}

export default CustomMessageTypes;
