import type { IncomingHttpHeaders } from 'http';

const getCurrentDomain = (headers?: IncomingHttpHeaders) => {
  if (headers) {
    const domain = headers['x-host'] ?? headers.host ?? '';
    return domain as string;
  }
  return global?.location?.hostname ?? '';
};

export default getCurrentDomain;
