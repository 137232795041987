/* eslint-disable no-restricted-imports */
import {
  getCookie,
  getCookies,
  deleteCookie,
  setCookie,
  hasCookie,
} from 'cookies-next';
import Cookies from 'js-cookie';
import removeMainDomainCookies from './helpers/removeMainDomainCookies';
import removeSubdomainCookies from './helpers/removeSubdomainCookies';
import isSsr from '../isSsr';
import getCurrentDomain from '../site/getCurrentDomain';

const Cookie = {
  list: (options?: CookieOptions) => {
    if (!isSsr()) {
      return Cookies.get();
    }
    return getCookies(options as OptionsType);
  },
  get: (name: string, options?: CookieOptions) => {
    if (!isSsr()) {
      return Cookies.get(name);
    }
    return getCookie(name, options as OptionsType) as string | undefined;
  },
  set: (name: string, value: unknown, options?: CookieOptions) => {
    try {
      if (!isSsr()) {
        Cookies.set(name, value as string, options);
      }
      setCookie(name, value, options);
    } catch (error) {
      console.error('Error setting cookie', name, value, error);
    }
  },
  remove: (name: string, options?: CookieOptions) => {
    const initOptions = options as OptionsType;
    const domain = getCurrentDomain(initOptions?.req?.headers);
    if (!isSsr()) {
      Cookies.remove(name, options);
    }
    deleteCookie(name, initOptions);
    removeSubdomainCookies(name, { ...initOptions, domain });
    removeMainDomainCookies(name, { ...initOptions, domain });
  },
  exists: (name: string, options?: CookieOptions) => {
    if (!isSsr()) {
      return !!Cookies.get(name);
    }
    return hasCookie(name, options as OptionsType);
  },
};

export default Cookie;
