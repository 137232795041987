import { from } from '@apollo/client';
import getEnv from '~/shared/utils/getEnv';
import isSsr from '~/shared/utils/isSsr';
import errorLink from './getErrorLink';
import getHttpLink from './getHttpLink';
import getLinkLogger from './getLinkLogger';
import getSubscriptionLink from './getSubscriptionLink';

type Props = ApolloLinkOptions;

const getLink = ({
  token,
  requestId,
  forwardedFor,
  isPrivate,
  context,
  uri: linkUri,
}: Props = {}) => {
  const uri = getUri(linkUri, isPrivate);
  const link = getHttpLink({
    uri,
    token,
    context,
    requestId,
    forwardedFor,
  });
  if (isSsr() || process.env.APP_ENV === 'test') {
    return from([getLinkLogger, errorLink, link]);
  }
  return from([getLinkLogger, errorLink, getSubscriptionLink(uri, link)]);
};

const getUri = (linkUri?: string, isPrivate?: boolean) => {
  if (isPrivate) {
    return process.env.GRAPHQL_PRIVATE_SERVER_URL ?? '';
  }
  if (isSsr()) {
    return linkUri ?? '/graphql';
  }
  return getEnv('GRAPHQL_CLIENT_URL') ?? linkUri ?? '';
};

export default getLink;
