/* eslint-disable no-restricted-imports */
import { deleteCookie } from 'cookies-next';
import getTopLevelDomain from '../../domain/getTopLevelDomain';

const removeMainDomainCookies = (name: string, options?: OptionsType) => {
  const bridgeDomain = getTopLevelDomain(options?.domain);
  deleteCookie(name, { ...options, domain: bridgeDomain });
  deleteCookie(name, { ...options, domain: `.${bridgeDomain}` });
};

export default removeMainDomainCookies;
